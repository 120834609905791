import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { path } from 'ramda';
import styled from '@emotion/styled';
import theme from '@greenberry/salal/theme';
import HeroImage from '@greenberry/salal/components/HeroImage';
import Heading from '@greenberry/salal/components/Heading';
import Text from '@greenberry/salal/components/Text';
import Section from '@greenberry/salal/components/Section';
import HighlightCard from '@greenberry/salal/components/HighlightCard';
import Button from '@greenberry/salal/components/Button';
import Icon from '@greenberry/salal/components/Icon';
import Introduction from '@greenberry/salal/components/Introduction';
import mq from '@greenberry/salal/utils/mediaQuery';

import SlideIntoView from '../../../components/SlideIntoView';
import NationalLayout from '../../../components/NationalLayout';
import themes from '../../../constants/themes';
import translateTheme from '../../../utils/translateTheme';
import parseTooltip from '../../../utils/parseTooltip';

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  & > a:first-of-type {
    margin-bottom: ${theme.spacing('s')};
    ${mq('2')} {
      margin-bottom: ${theme.spacing('m')};
    }
  }
`;

const Start = ({ pageContext: { copy }, ...props }) => {
  const images = useStaticQuery(graphql`
    {
      students: file(relativePath: { eq: "leerlingen.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      financial: file(relativePath: { eq: "financien.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      organization: file(relativePath: { eq: "organisatie.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <NationalLayout
      title={parseTooltip(copy.heroHeading)}
      body={parseTooltip(copy.heroBody)}
      seoMetaTags={copy.seoMetaTags}
      heroImage={
        <HeroImage
          src="/images/po_light.svg"
          backgroundSize="40%"
          backgroundPosition="center right 10%"
          opacity="1"
        />
      }
      {...props}
    >
      <Section>
        <Introduction
          title={parseTooltip(copy.introHeading)}
          text={copy.introBody}
          html
        />
      </Section>
      {themes.map((key, index) => (
        <Section size={'2'} key={key}>
          <SlideIntoView minHeight="250px">
            <HighlightCard
              node={
                <Img fluid={path([key, 'childImageSharp', 'fluid'], images)} />
              }
              orientation={index % 2 === 0 ? 'ltr' : 'rtl'}
            >
              <Heading appearance="secondary" weight="semibold" size="xxxl">
                {parseTooltip(copy[`${key}Heading`])}
              </Heading>
              <Text size="l">{parseTooltip(copy[`${key}Body`])}</Text>
              <ButtonsWrapper>
                <Link
                  to={`/landelijk-inzicht/primair-onderwijs/${translateTheme(
                    key
                  )}`}
                >
                  <Button
                    color="primary"
                    disabled={false}
                    small={false}
                    styling="solid"
                    type="button"
                  >
                    <span>Primair onderwijs</span>
                    <Icon size="lg" type="arrowCircleRight" />
                  </Button>
                </Link>
                <Link
                  to={`/landelijk-inzicht/voortgezet-onderwijs/${translateTheme(
                    key
                  )}`}
                >
                  <Button
                    color="primary"
                    disabled={false}
                    small={false}
                    styling="solid"
                    type="button"
                  >
                    <span>Voortgezet onderwijs</span>
                    <Icon size="lg" type="arrowCircleRight" />
                  </Button>
                </Link>
              </ButtonsWrapper>
            </HighlightCard>
          </SlideIntoView>
        </Section>
      ))}
    </NationalLayout>
  );
};

Start.propTypes = {
  pageContext: PropTypes.shape({
    copy: PropTypes.shape({
      heroHeading: PropTypes.string.isRequired,
      heroBody: PropTypes.string.isRequired,
      introHeading: PropTypes.string.isRequired,
      introBody: PropTypes.string.isRequired,
      seoMetaTags: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
};

export default Start;
